@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* general */
* {
  font-family: montserrat !important;
  color: #111;
}

body {
  background-color: #f8f7ff;
  overflow-x: hidden;
  font-size: 16px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.head {
  font-size: 3.15rem;
}

.midH {
  font-size: 2.5rem;
  @apply pb-3;
}

.smallH {
  font-size: 21px;
  /* font-weight: 600 !importa    nt; */
}

.subH {
  font-size: 0.75rem;
  text-transform: uppercase;
  /* color: #aaaaaa; */
  letter-spacing: 3px;
  margin-bottom: 10px;
}

.link {
  display: inline;
  margin-bottom: 1em;
  color: #111;
  font-weight: bold;
  text-decoration: underline 0.15em rgba(0, 0, 0, 0);
  text-underline-offset: 0.2em;
  transition: text-decoration-color 300ms, text-underline-offset 300ms;
}

.link:hover {
  color: #111;
  text-decoration-color: #11111100;
  text-underline-offset: 0.4em;
}

/* nav */
.nav {
  height: 100px;
}

.nav .logo {
  font-weight: 900;
  text-decoration: none;
  margin: 35px 0px 0px 35px;
  position: fixed;
  top: 0;
  left: 0;
}

.nav .logo h2,
.nav .logo p {
  width: 200px;
  display: inline-block;
  position: fixed;
  left: 85px;
}

.nav .logo h2 {
  font-size: 20px;
  top: 35px;
}

.nav .logo p {
  letter-spacing: 0.4px;
  margin-left: 1px;
  font-size: 12px;
  color: #8e8e8e;
  font-weight: 300;
  top: 61px;
}

.nav img {
  width: 42px;
}

/* menu */
label .menu {
  position: fixed;
  right: 35px;
  top: 35px;
  z-index: 100;
  width: 42px;
  height: 42px;
  background: #272727;
  border-radius: 50% 50% 50% 50%;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  box-shadow: 0 0 0 0 #272727, 0 0 0 0 #272727;
  cursor: pointer;
}

label .hamburger {
  position: fixed;
  top: 55px;
  right: 45px;
  width: 21px;
  height: 2.5px;
  background: #ccc;
  display: block;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

label .hamburger:after,
label .hamburger:before {
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #ccc;
}

label .hamburger:before {
  top: -8px;
}

label .hamburger:after {
  bottom: -8px;
}

label input {
  display: none;
}

label input:checked + .menu {
  box-shadow: 0 0 0 100vw #272727, 0 0 0 100vh #272727;
  border-radius: 0;
}

label input:checked + .menu .hamburger {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

label input:checked + .menu .hamburger:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  bottom: 0;
}

label input:checked + .menu .hamburger:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 0;
}

label input:checked + .menu + ul {
  /* display: block;
    color: #ccc;
    opacity: 1; */
  top: 50%;
  right: 45%;
}

label ul {
  z-index: 200;
  position: fixed;
  text-align: center;
  top: -6vh;
  right: -16vh;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* display: none;
    color: #ccc00;
    opacity: 0; */
  -webkit-transition: 0.25s 0s ease-in-out;
  transition: 0.25s 0s ease-in-out;
}

li::marker {
  color: rgba(255, 255, 255, 0);
}

label a {
  margin-bottom: 1em;
  display: block;
  color: #ccc;
  font-weight: bold;
  font-size: 25px !important;
  text-decoration: underline 0.15em rgba(0, 0, 0, 0);
  text-underline-offset: 0.2em;
  transition: text-decoration-color 300ms, text-underline-offset 300ms;
}

label a:hover {
  color: #ccc;
  text-decoration-color: #ccc;
  text-underline-offset: 0.4em;
}

/* landing */
.landing {
  height: 90vh;
}

.landing .left {
  padding-top: 250px;
}

.landing .right {
  padding-top: 100px;
  text-align: center !important;
}

.imageRot {
  width: 80%;
  animation: rotation 100s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

/* menu */

.toggleMenu {
  position: fixed;
  top: 3.5vh;
  right: 4vh;
  cursor: pointer;
  font-size: 25px;
}

/* loader */

.loader-component .loader-container {
  position: relative;
  height: 200px;
}

.loader-component h1 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.loader-component .loader-container .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  width: 160px;
  height: 100px;
  margin-left: -80px;
  margin-top: -50px;
  border-radius: 5px;
  background: #1e3f57;
  animation: dot1_ 3s cubic-bezier(0.55, 0.3, 0.24, 0.99) infinite;
}

.loader-component .loader-container .loader:nth-child(2) {
  z-index: 11;
  width: 150px;
  height: 90px;
  margin-top: -45px;
  margin-left: -75px;
  border-radius: 3px;
  background: #3c517d;
  animation-name: dot2_;
}

.loader-component .loader-container .loader:nth-child(3) {
  z-index: 12;
  width: 40px;
  height: 20px;
  margin-top: 50px;
  margin-left: -20px;
  border-radius: 0 0 5px 5px;
  background: #6bb2cd;
  animation-name: dot3_;
}

@keyframes dot1_ {
  3%,
  97% {
    width: 160px;
    height: 100px;
    margin-top: -50px;
    margin-left: -80px;
  }

  30%,
  36% {
    width: 80px;
    height: 120px;
    margin-top: -60px;
    margin-left: -40px;
  }

  63%,
  69% {
    width: 40px;
    height: 80px;
    margin-top: -40px;
    margin-left: -20px;
  }
}

@keyframes dot2_ {
  3%,
  97% {
    height: 90px;
    width: 150px;
    margin-left: -75px;
    margin-top: -45px;
  }

  30%,
  36% {
    width: 70px;
    height: 96px;
    margin-left: -35px;
    margin-top: -48px;
  }

  63%,
  69% {
    width: 32px;
    height: 60px;
    margin-left: -16px;
    margin-top: -30px;
  }
}

@keyframes dot3_ {
  3%,
  97% {
    height: 20px;
    width: 40px;
    margin-left: -20px;
    margin-top: 50px;
  }

  30%,
  36% {
    width: 8px;
    height: 8px;
    margin-left: -5px;
    margin-top: 49px;
    border-radius: 8px;
  }

  63%,
  69% {
    width: 16px;
    height: 4px;
    margin-left: -8px;
    margin-top: -37px;
    border-radius: 10px;
  }
}
