@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  min-height: 100%;
}

.link {
  font-family: Montserrat;
  position: relative;
  text-decoration: none;
}

.link:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 0px;
  left: 0%;
  background: black;
  transition: width 0.3s ease;
}

.link:hover:after {
  width: 100%;
  left: 0%;
  background: black;
}

.construction {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.construction h1 {
  font-family: Montserrat;
  font-size: 40px;
  color: #ccc;
}

.construction .logo-img {
  width: 100px;
  margin: 30px;
  filter: saturate(0.3) opacity(0.3);
  transition: filter 1s;
}
